import React from 'react';

function Mentions() {
  return (
    <div style={{minHeight: '90vh', padding: '15px 25px'}} className="mentions">
      <h1>Mentions Légales</h1>
      <p>Bienvenue sur la page des mentions légales.</p>
      <p>Tous les détails légaux concernant ce site web se trouvent ici.</p>
      <br />
      <h2>Informations légales</h2>
      <p>
        <strong>SARL THOMAS EVASION</strong>
        <br />
        3 RUE DESCOURTIVE
        <br />
        89600 SAINT FLORENTIN
        <br />
        <strong>SIRET :</strong> 92928395000012
        <br />
        <strong>Numéro TVA intracommunautaire :</strong> FR82929283950
        <br />
        <strong>CODE NAF/APE :</strong> 5510Z
        <br />
        <strong>RCS :</strong> Auxerre
      </p>
    </div>
  );
}

export default Mentions;
